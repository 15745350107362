import Vue from 'vue'
import App from './App.vue'
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import http from "./utils/request"
import router from "./router/index"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Meta from 'vue-meta';
import VueParticles from 'vue-particles'
Vue.use(VueParticles)
Vue.use(Meta)
Vue.use(ViewUI);
Vue.use(ElementUI);
//将二次封装的axios添加到vue实例上
Vue.prototype.$http = http;


Vue.prototype.$addStorageEvent = function (key, data) {
    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent('StorageEvent');
    const storage = {
      setItem: function (k, val) {
        localStorage.setItem(k, val);
        // 初始化创建的事件
        newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
        // 派发对象
        window.dispatchEvent(newStorageEvent);
      }
    }
    return storage.setItem(key, data);

}

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

