import Vue from 'vue'
import VueRouter from 'vue-router'
import ViewUI from 'view-design';
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Index',
        component: () => import('@/components/index/index.vue')
    },
    {
        path: '/doc',
        name: 'Doc',
        component: () => import('@/components/doc/doc.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/components/login/login.vue')
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('@/components/login/register.vue')
    },
    {
        path: '/download',
        name: 'Download',
        component: () => import('@/components/download/download.vue')
    }
]


const router = new VueRouter({
    mode:'history',
    routes
})



router.afterEach(() => {
    ViewUI.LoadingBar.finish();
    document.querySelector("body").setAttribute("style", "overflow: auto !important;");
    window.scrollTo(0, 0);
});

// 路由拦截
router.beforeEach((to, from, next) => {
    ViewUI.LoadingBar.start();
    console.log(to);
    console.log(from);
    if (to.path == '/') {
        next();
        return;
    }
    if (to.path == '/doc') {
        next();
        return;
    }
    next({ path: '/'})
    next();
    return;
})

export default router